/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- ControlValueAccessor interface*/
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Utility } from "@ignite/ignite-common";
import { Subject } from "rxjs";

@Component({
  selector: "ignite-surname",
  templateUrl: "./ignite-surname.component.html",
  styleUrls: ["./ignite-surname.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IgniteSurnameComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IgniteSurnameComponent),
      multi: true,
    },
  ],
})
export class IgniteSurnameComponent implements AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges, Validator {
  static nextId = 0;
  unsubscribe$: Subject<void> = new Subject<void>();
  hasNoSurname = false;
  showCheckbox = false;

  @Input() id: string;
  @Input() label: string;
  @Input() enterOnBehalfOf = "Myself";
  @Input() placeholder: string;
  @Input() frmCtrl: UntypedFormControl;

  @ViewChild("inputCtrl") inputCtrl: ElementRef<HTMLElement>;
  @ViewChild("nosurname") nosurname: ElementRef<HTMLElement>;

  public onTouched: () => void = () => {
    // do nothing
  };

  ngAfterViewInit(): void {
    let wrapper: any = this.inputCtrl.nativeElement.closest(".mat-mdc-form-field");
    if (!Utility.IsNull(wrapper) && !Utility.IsNull(this.nosurname)) {
      wrapper = wrapper.getElementsByClassName("mat-mdc-form-field-subscript-wrapper");
      if (wrapper.length > 0) {
        wrapper[0].appendChild(this.nosurname.nativeElement);
      }
    }
    if (!Utility.IsNull(this.frmCtrl)) {
      this.showCheckbox = this.frmCtrl.enabled;
      if (this.frmCtrl.value === ".") {
        this.hasNoSurname = true;
      }
      this.frmCtrl.valueChanges.subscribe({
        next: (value: string) => {
          if (!this.frmCtrl.dirty) {
            this.showCheckbox = this.frmCtrl.enabled;
          }

          if (value === ".") {
            this.hasNoSurname = true;
          }
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes): void {
    this.frmCtrl.updateValueAndValidity({ emitEvent: true });
  }

  isEmpty(): boolean {
    return Utility.IsEmptyOrNull(this.frmCtrl.value);
  }

  writeValue(val: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    val && this.frmCtrl.setValue(val, { emitEvent: true });
  }

  registerOnChange(fn: any): void {
    this.frmCtrl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.frmCtrl.disable() : this.frmCtrl.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.frmCtrl.valid ? null : this.frmCtrl.errors;
  }

  onNoSurnameChanged($event: MatCheckboxChange): void {
    this.frmCtrl.markAsDirty();
    if ($event.checked) {
      this.frmCtrl.setValue(".");
      this.frmCtrl.disable();
    } else {
      this.frmCtrl.setValue("");
      this.frmCtrl.enable();
      setTimeout(() => this.inputCtrl.nativeElement.focus(), 100);
    }
    this.frmCtrl.markAsDirty();
  }
}
