/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import {
  QueryParams,
  SearchCriteria,
  SearchFilterGroup,
  SearchFilter,
  SortCriteria, PersonPlaceNameValidator
} from "@ignite/ignite-common";
import { LocationDataSource } from "../../datasources/locationDataSource";
import { LocationService } from "../../services/location-service";
import { PageEvent } from "@angular/material/paginator";
import { MatTabGroup } from "@angular/material/tabs";
import { MyCustomValidator } from "./my-custom.validator";
import { PaymentDataSource } from "../../datasources/paymentDataSource";
import { PaymentService } from "../../services/payment-service";
import { UserActionsComponent } from "./user-actions/user-actions.component";
import { GridCustomContentComponent } from "./grid-custom-content/grid-custom-content.component";
import data from "../../../assets/data/countries.json";
import { IgniteBaseComponent, IgniteGridColumnDef, IgniteGridFieldDef, IgnitePanelMenuOption, UIControlType } from "../../../../libs/ui-common/src";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent extends IgniteBaseComponent implements OnInit, AfterViewChecked {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  locationDS: LocationDataSource;
  query = new QueryParams(1, 10, undefined, "name");
  searchCriteria = new SearchCriteria();

  gridColumns: IgniteGridColumnDef[];
  gridFields: IgniteGridFieldDef[];

  countries = data.countries;
  countryCallingCodes = data.countryCallingCodes;

  panelMenuOptions: IgnitePanelMenuOption[] = [];

  customColumns: IgniteGridColumnDef[];
  customFields: IgniteGridFieldDef[];
  customDS: PaymentDataSource;

  constructor(
    private cdRef: ChangeDetectorRef,
    private locationService: LocationService,
    private paymentService: PaymentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.searchCriteria.id = "TestTakerGrid";
    this.searchCriteria.searchText = "John Doe";
    this.searchCriteria.filterGroups = [];
    let sfg = new SearchFilterGroup("TTG-Location", "Location", "location", "==");
    sfg.filters = [];
    this.countries.forEach((c) => {
      sfg.filters.push(new SearchFilter(c.value, c.text));
    });
    this.searchCriteria.filterGroups.push(sfg);

    sfg = new SearchFilterGroup("TTG-Watch", "Watch", "watch", "==");
    sfg.filters = [new SearchFilter("true", "Watching"), new SearchFilter("false", "Not watching", true)];
    this.searchCriteria.filterGroups.push(sfg);

    sfg = new SearchFilterGroup("TTG-Status", "Status", "status", "==");
    sfg.filters = [
      new SearchFilter("Scored", "Scored received"),
      new SearchFilter("Match", "Match found"),
      new SearchFilter("ConsentPending", "Pending parental consent and approval"),
    ];
    this.searchCriteria.filterGroups.push(sfg);

    this.gridColumns = [];
    this.gridColumns.push(
      {
        header: "Name",
        field: "main.name",
        widthPercent: "85",
        sortable: true,
        sortDirection: "asc",
        isPrimarySort: true,
      },
      {
        header: "Date",
        field: "date",
        widthPercent: "10",
        sortable: true,
        sortDirection: "asc",
        isPrimarySort: false,
      },
      {
        header: "",
        field: "actions",
        customComponent: UserActionsComponent,
        alignment: "right",
        widthPercent: "5",
        shownWhenSelected: true,
      }
    );

    this.gridFields = [];
    this.gridFields.push(
      {
        name: "date",
        label: "Date created",
        controlType: UIControlType.InputDate,
      },
      {
        name: "main.name",
        label: "Name",
        placeHolder: "The name of the location",
        controlType: UIControlType.InputText,
        required: true,
        customValidators: [
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            validator: new PersonPlaceNameValidator().validate.bind(this),
            customMessages: [{ key: "invalidChars", message: "Name contains invalid characters" }],
          },
        ],
      },
      {
        name: "streetAddress",
        label: "Street Address",
        placeHolder: "The location street address",
        controlType: UIControlType.TextArea,
        required: true,
        customValidators: [
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            validator: new PersonPlaceNameValidator().validate.bind(this),
            customMessages: [{ key: "invalidChars", message: "Name contains invalid characters" }],
          },
        ],
      },
      {
        name: "role",
        label: "User Role",
        placeHolder: "Tue user's role",
        controlType: UIControlType.DropdownList,
        required: false,
        selectOptions: [
          { value: "01", text: "Administrator" },
          { value: "02", text: "Partner" },
          { value: "03", text: "Test Taker" },
        ],
        customValidators: [
          {
            validator: new MyCustomValidator().validate.bind(this) as ValidatorFn,
            customMessages: [],
          },
        ],
      },
      {
        name: "stateProvince",
        label: "State/Province",
        placeHolder: "State or Province",
        controlType: UIControlType.MultiSelect,
        required: false,
        selectOptions: [
          { value: "*", text: "All States" },
          { value: "01", text: "New South Wales" },
          { value: "02", text: "Victoria" },
          { value: "03", text: "Western Australia" },
          { value: "04", text: "Newfoundland and Labrador" },
          { value: "05", text: "Northwest Territories" },
          { value: "06", text: "Nova Scotia" },
          { value: "07", text: "Nunavut" },
          { value: "08", text: "Ontario" },
          { value: "09", text: "Saskatchewan" },
          { value: "10", text: "Yukon" },
        ],
      },
      {
        name: "city",
        label: "City",
        placeHolder: "City",
        controlType: UIControlType.InputText,
        required: true,
      },
      {
        name: "postalCode",
        label: "Zip/Postal Code",
        placeHolder: "The zip or postal code",
        controlType: UIControlType.InputNumber,
        required: false,
      },
      {
        name: "country",
        label: "Country",
        placeHolder: "The country",
        controlType: UIControlType.Autocomplete,
        required: true,
        selectOptions: this.countries,
      },
      {
        name: "phone",
        label: "Phone Number",
        placeHolder: "The phone number of this location",
        controlType: UIControlType.InputTelephone,
        countryCallingCodes: this.countryCallingCodes,
        required: false,
      },
      {
        name: "actions",
        label: "ACTIONS",
        controlType: UIControlType.Hidden,
      }
    );

    this.locationDS = new LocationDataSource(this.locationService);
    this.loadData();

    this.customColumns = [
      {
        header: "Date",
        field: ["date"],
        sortable: true,
        sortDirection: "asc",
        isPrimarySort: true,
        widthPercent: "60",
        mapValueFunction: (val) => {
          return val.toLocaleString("en-US", { timeZone: "UTC", month: "long", day: "2-digit", year: "numeric" });
        },
      },
      { header: "Status", field: "status", widthPercent: "20", shownWhenSelected: true },
      { header: "Order Number", field: "orderNumber", widthPercent: "20", alignment: "right", shownWhenSelected: true },
    ];
    this.customFields = [
      {
        name: "date",
        controlType: UIControlType.Hidden,
      },
      {
        name: "status",
        controlType: UIControlType.Hidden,
      },
      {
        name: "orderNumber",
        controlType: UIControlType.Hidden,
      },
      {
        name: "mycustomcontent",
        controlType: UIControlType.CustomContent,
        customComponent: GridCustomContentComponent,
        displayLayout: "fullWidth"
      }
    ];
    this.customDS = new PaymentDataSource(this.paymentService);
    this.customDS.loadPayments(new QueryParams());
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  private loadData() {
    this.locationDS.loadLocations(this.query);
  }

  onPaging($event: PageEvent) {
    this.query.page = $event.pageIndex;
    this.query.pageSize = $event.pageSize;
    this.loadData();
  }

  onAddLocation($event: Location) {
    console.log($event);
  }

  onSort($event: SortCriteria) {
    this.query.sort = SortCriteria.getSorts($event.sortColumns);
    this.loadData();
  }

  onSearch($event: SearchCriteria) {
    console.log($event.searchText + "---" + SearchCriteria.getFiltersQuery($event.filterGroups));
  }
}
