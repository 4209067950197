/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component } from "@angular/core";
import { IgniteGridCustomControl } from "../../../../../libs/ui-common/src/lib/models/ignite-grid-custom-control";

@Component({
  selector: "grid-custom-content",
  templateUrl: "./grid-custom-content.component.html",
  styleUrls: ["./grid-custom-content.component.scss"],
})
export class GridCustomContentComponent implements IgniteGridCustomControl {
  rowIndex = 0;
  data: any;

  constructor() {}

  setValue(data: any): void {
    this.data = data;
  }

  getPrice(n: number): string {
    const formatter = Intl.NumberFormat(undefined, { currency: "USD", style: "currency" });
    return formatter.format(n);
  }
}
