<mat-form-field class="ignite-surname-field" [attr.id]="id" appearance="outline" [hideRequiredMarker]="true" [class.is-empty]="isEmpty()">
  <input #inputCtrl [class.hidden]="hasNoSurname" matInput [formControl]="frmCtrl" required maxlength="50" trim="blur" />
  <mat-label *ngIf="label !== ''" floatLabel>{{ label }}</mat-label>
  <mat-error id="last_name_error_required" *ngIf="frmCtrl.hasError('required')" i18n
    >Enter all surnames or last names, as shown on {{ enterOnBehalfOf === "Myself" ? "your" : enterOnBehalfOf + "'s" }} ID
  </mat-error>
  <mat-error id="last_name_error_pattern" *ngIf="frmCtrl.hasError('invalidChars')" i18n>{{ label }} contains invalid characters</mat-error>
  <mat-error id="last_name_error_does_not_start_with_letter" *ngIf="frmCtrl.hasError('doesNotStartWithALetter')" i18n
    >{{ label }} must start with a letter</mat-error
  >
</mat-form-field>

<div [class.hidden]="!showCheckbox" #nosurname class="no-surname-wrapper">
  <mat-checkbox [(ngModel)]="hasNoSurname" (change)="onNoSurnameChanged($event)">
    {{ enterOnBehalfOf === "Myself" ? "I do" : enterOnBehalfOf + " does" }} not have a surname on {{ enterOnBehalfOf === "Myself" ? "my" : "their" }} passport/ID
  </mat-checkbox>
</div>
