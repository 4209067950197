<div *ngIf="imageDefs && imageDefs.length > 0" class="ignite-image-carousel-container">
  <ignite-swiper>
    <div swiper-card *ngFor="let imgDef of imageDefs">
      <div class="title" *ngIf="imgDef.title">
        {{ imgDef.title }}
      </div>
      <div
        class="image"
        [style.background-image]="'url(' + imgDef.imageUrl + ')'"
        [style.width]="imgDef.width ? imgDef.width : '100%'"
        [style.height]="imgDef.height ? imgDef.height : '100%'"
      >
        <div class="expand-container" *ngIf="imgDef.expandable" (click)="expandImage(imgDef)"></div>
        <i class="fas fa-expand" (click)="expandImage(imgDef)"></i>
      </div>
      <div class="description" *ngIf="imgDef.description">
        {{ imgDef.description }}
      </div>
    </div>
  </ignite-swiper>
</div>
