import { SoloCardComponent } from "./components/solo-card/solo-card.component";
import { AppComponent } from "./app.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StepperExampleComponent } from "./components/stepper/stepper.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { FormFieldsComponent } from "./components/form-fields/form-fields.component";
import { HelpComponent } from "./components/help/help.component";
import { GridComponent } from "./components/grid/grid.component";

const routes: Routes = [
  { path: "forbidden", component: AppComponent },
  { path: "unauthorized", component: AppComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        ...routes,
        { path: "", component: DashboardComponent },
        { path: "dashboard", component: DashboardComponent },
        { path: "formfields", component: FormFieldsComponent },
        { path: "grid", component: GridComponent },
        { path: "stepper", component: StepperExampleComponent },
        { path: "solo-card", component: SoloCardComponent },
        { path: "help", component: HelpComponent },
        {
          path: "**",
          redirectTo: "",
        },
      ],
      { anchorScrolling: "enabled" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
