<div class="ignite-solo-card-backdrop">
  <div class="ignite-solo-card-container">
    <div class="ignite-solo-card">
      <div>
        <img id="logo_image" class="ignite-solo-card-logo-image" [src]="logoUrl" />
      </div>
      <div class="ignite-solo-card-title">
        <ng-content select="[solo-card-title]"></ng-content>
      </div>
      <div class="ignite-solo-card-content">
        <ng-content select="[solo-card-content]"></ng-content>
        <div class="ignite-solo-card-actions">
          <ng-content select="[solo-card-actions]"></ng-content>
        </div>
      </div>
    </div>
    <ignite-footer [showCookiesLink]="showCookiesLink"></ignite-footer>
  </div>
</div>
