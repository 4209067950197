<div class="ignite-duration-container">
  <mat-label *ngIf="label !== ''" class="ignite-label">{{ label }}</mat-label>
  <form [formGroup]="form">
    <div class="ignite-duration-fields" id="duration">
      <mat-form-field id="year-field" [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Years</mat-label>
        <input matInput type="number" formControlName="year" (change)="updateDuration()" />
      </mat-form-field>

      <mat-form-field id="month-field" [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Months</mat-label>
        <input matInput type="number" formControlName="month" (change)="updateDuration()" />
      </mat-form-field>

      <mat-form-field id="day-field" [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Days</mat-label>
        <input matInput type="number" formControlName="day" (change)="updateDuration()" />
      </mat-form-field>
    </div>
  </form>
</div>
