/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { AfterViewInit, Component, Input, OnChanges, ViewChild } from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { CustomHostDirective } from "../../directives/custom-host.directive";
import { IgniteGridCustomControl } from "../../models/ignite-grid-custom-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-grid-custom",
  templateUrl: "./ignite-grid-custom.component.html",
})
export class IgniteGridCustomComponent implements AfterViewInit, OnChanges {
  @Input() component: any;
  @Input() value: any;
  @Input() rowIndex: number;
  @ViewChild(CustomHostDirective, { static: true }) customHost: CustomHostDirective;
  private componentInstance: any;

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  ngOnChanges(changes): void {
    if (!Utility.IsNull(this.componentInstance)) {
      this.componentInstance.setValue(changes.value.currentValue);
      this.componentInstance.rowIndex = this.rowIndex;
    }
  }

  loadComponent(): void {
    const componentRef = this.customHost.viewContainerRef.createComponent(this.component);
    this.componentInstance = componentRef.instance as IgniteGridCustomControl;
    this.componentInstance.setValue(this.value);
    this.componentInstance.rowIndex = this.rowIndex;
  }
}
