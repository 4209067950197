/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of, switchMap } from "rxjs";
import { IgniteGridCustomControl } from "../../../../../libs/ui-common/src/lib/models/ignite-grid-custom-control";
import { IgnitePanelMenuOption } from "../../../../../libs/ui-common/src/lib/models/ignite-panel-menu-option";
import { ConfirmDialogComponent } from "../../../../../libs/ui-common/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component";
@Component({
  selector: "user-actions",
  templateUrl: "./user-actions.component.html",
  styleUrls: ["./user-actions.component.scss"],
})
export class UserActionsComponent implements IgniteGridCustomControl {
  rowIndex = 0;
  menuOptions: IgnitePanelMenuOption[];
  location: any;

  constructor(private dialog: MatDialog) {}

  setValue(location): void {
    this.location = location;
    this.menuOptions = [];
    this.setupMFAMenu();
    this.setupActivateMenu();
    this.setupDeleteMenu();
  }

  private setupActivateMenu() {
    const panelOption = new IgnitePanelMenuOption("Activate location", "Deactivate location", "fa-user-plus", "fa-user-slash");
    panelOption.selected = this.location.isActive;
    panelOption.toggleCallback = (selected: boolean) => {
      return this.onActiveChange(!selected);
    };
    this.menuOptions.push(panelOption);
  }

  private setupMFAMenu() {
    const panelOption = new IgnitePanelMenuOption("Reset MFA", "Reset MFA", "fa-shield", "fa-shield");
    panelOption.toggleCallback = (selected: boolean) => {
      return this.resetMFA();
    };
    this.menuOptions.push(panelOption);
  }

  private setupDeleteMenu() {
    const panelOption = new IgnitePanelMenuOption(
      "Delete location",
      "Delete location",
      "fa-trash",
      "fa-trash",
      undefined,
      undefined,
      "red"
    );
    panelOption.toggleCallback = (selected: boolean) => {
      return this.delete();
    };
    this.menuOptions.push(panelOption);
  }

  private resetMFA(): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmDialogComponent);
    dialog.componentInstance.setup(`Are you sure you want to reset the MFA for '${this.location.main.name}'?`, "Reset MFA");
    return dialog.afterClosed().pipe(
      switchMap((confirmed) => {
        if (confirmed) {
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  private delete(): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmDialogComponent);
    dialog.componentInstance.setup(`Are you sure you want to delete location '${this.location.main.name}'?`, "Delete location");
    return dialog.afterClosed().pipe(
      switchMap((confirmed) => {
        if (confirmed) {
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  private onActiveChange(active: boolean): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmDialogComponent);

    dialog.componentInstance.setup(
      `Are you sure you want to ${active ? "deactivate" : "activate"} location '${this.location.main.name}'?`,
      active ? "Deactivate location?" : "Activate location?"
    );

    return dialog.afterClosed().pipe(
      switchMap((confirmed) => {
        if (confirmed) {
          return of(!active);
        } else {
          return of(active);
        }
      })
    );
  }
}
