<div #panelMenu [id]="id" class="ignite-panel-menu-container">
  <div class="ignite-panel-menu" (click)="toggleMenu($event)">
    <span class="ignite-panel-menu-indicator" *ngFor="let option of menuOptions">
      <i *ngIf="option.selectedIndicator && option.selected" class="fas" [ngClass]="option.selectedIndicator"></i>
      <i *ngIf="option.unselectedIndicator && !option.selected" class="fas" [ngClass]="option.unselectedIndicator"></i>
    </span>
    <i class="fal fa-ellipsis-v"></i>
  </div>
  <div #popup class="ignite-panel-menu-popup" [class.opened]="isOpened">
    <div
      [id]="option.selectLabel + '-panel-item' | replace : '[ ]' : ''"
      class="ignite-panel-menu-option"
      *ngFor="let option of menuOptions"
      (click)="onOptionClick($event, option)"
    >
      <i *ngIf="!option.selected && option.selectIcon !== null" class="fal" [ngClass]="option.selectIcon"></i>
      <i *ngIf="option.selected && option.unselectIcon !== null" class="fal" [ngClass]="option.unselectIcon"></i>
      <span [ngStyle]="{ color: option.textColor === null ? 'black' : option.textColor }">
        {{ option.selected ? option.unselectLabel : option.selectLabel }}</span
      >
    </div>
  </div>
</div>
