<ignite-sidebar>
  <ignite-sidebar-button
    icon="fa-bars"
    [badgeValue]="sideMenuOpened ? 'x' : ''"
    (clickEvent)="drawer.toggle(); sideMenuOpened = !sideMenuOpened"
  ></ignite-sidebar-button>
  <ignite-sidebar-button
    icon="fa-edit"
    [badgeValue]="notepadDialog && notepadDialog.state === 'opened' ? 'x' : ''"
    (clickEvent)="sideBarClick('notePad')"
  ></ignite-sidebar-button>
  <ignite-sidebar-button
    icon="fa-bell"
    [badgeValue]="notifications"
    badgeColor="warn"
    (clickEvent)="sideBarClick('notitification')"
  ></ignite-sidebar-button>
  <ignite-sidebar-button icon="fa-question-circle" (clickEvent)="sideBarClick('help')"></ignite-sidebar-button>
</ignite-sidebar>

<mat-drawer-container autosize>
  <mat-drawer ignite-sidenav #drawer>
    <div class="expansion-panel-container">
      <mat-accordion class="expansion-panel" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-description>
              <div class="expansion-header"><i class="fas fa-lock-alt"></i><span>Module 1- Improving listening comprehension</span></div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="expansion-item">1. Welcome to listening skills</div>
          <div class="expansion-item">2. Listening for main ideas or specif details</div>
          <div class="expansion-item">3. Identifying purpose and context in what...</div>
          <div class="expansion-item">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-description>
                  <div class="expansion-item">4. TestManagement (optional)</div>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-description>
              <div class="expansion-header"><i class="fas fa-lock-alt"></i><span>Module 2 - Listening for different purpose</span></div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="expansion-item">1. Welcome to listening skills</div>
          <div class="expansion-item">2. Listening for main ideas or specif details</div>
          <div class="expansion-item">3. Identifying purpose and context in what...</div>
          <div class="expansion-item">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-description>
                  <div class="expansion-item">4. TestManagement (optional)</div>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <div class="dashboard-panel">
      <div class="dashboard-col">
        <div class="carousel-container">
          <ignite-image-carousel [imageDefs]="imageDefs"></ignite-image-carousel>
        </div>
      </div>
      <div class="dashboard-col">
        <div class="dashboard-cell">
          <mat-card ignite-dashboard-card class="alert">
            <mat-card-header>
              <div mat-card-avatar class="incomplete-profile-image"></div>
              <mat-card-title>Your profile is incomplete</mat-card-title>
            </mat-card-header>
            <mat-card-content> Make sure you're ready for your test by completing the following steps. </mat-card-content>
            <mat-card-actions class="ignite-dialog-buttons-container">
              <button mat-button class="ignite-button" color="accent" [disabled]="true"><i class="fas fa-check"></i>Create account</button>
              <button mat-button class="ignite-button" color="primary">Verify email</button>
              <button mat-button class="ignite-button" color="secondary">Learn more</button>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="dashboard-cell">
          <mat-card ignite-dashboard-card>
            <mat-card-header>
              <div mat-card-avatar class="special-arrangements-image"></div>
              <mat-card-title>Special arrangements</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              Please launch and complete the form. Our support team will notify you if the request is accepted. You'll then need to contact
              our call centre to book the test.
            </mat-card-content>
            <mat-card-actions class="ignite-dialog-buttons-container">
              <button mat-button class="ignite-button" color="primary">Launch form</button>
              <button mat-button class="ignite-button" color="secondary">Learn more</button>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="dashboard-cell">
          <mat-card ignite-dashboard-card important="true">
            <mat-card-header>
              <div mat-card-avatar class="waiting-for-results-image"></div>
              <mat-card-title>We're scoring your test</mat-card-title>
            </mat-card-header>
            <mat-card-content
              >We'll update you as soon as your score is available.
              <mat-progress-bar ignite-progress-bar color="contrast" mode="determinate" value="40"></mat-progress-bar>
            </mat-card-content>
            <mat-card-actions class="ignite-dialog-buttons-container">
              <button mat-button class="ignite-button" color="secondary">Learn more</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<notepad-dialog (changeEvent)="onNotesChanged($event)" id="np-01"></notepad-dialog>
