import { AfterContentChecked, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Component({
  selector: "ignite-swiper",
  templateUrl: "./ignite-swiper.component.html",
  styleUrls: ["./ignite-swiper.component.scss"],
})
export class IgniteSwiperComponent implements OnInit, AfterContentChecked {
  static nextId = 0;
  @Input() id: string;
  @Input() initialCardIdx = 0;
  @Input() enableSwiperAtSize = 0;
  @ViewChild("cardsContainer", { static: true }) cardsContainer: ElementRef<HTMLDivElement>;
  cards: Element[] = [];
  currentIdx = 0;
  private scrollEl: HTMLElement;
  private swiperDisabled = false;
  private swipeCoord?: [number, number];

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-swiper-${IgniteSwiperComponent.nextId++}`;
    }
  }

  ngAfterContentChecked(): void {
    if (this.cards.length === 0) {
      this.cards = Array.from(this.cardsContainer.nativeElement.children);
      this.cards.forEach((card: HTMLElement) => {
        card.classList.add("ignite-swiper-card");
      });
      this.scrollEl = this.cardsContainer.nativeElement.closest(".ignite-swiper");
      this.cardsContainer.nativeElement.style.width = `${this.cards.length * 100}%`;
      this.onResize();
      if (this.initialCardIdx > 0) {
        this.selectCard(this.initialCardIdx);
      }
    }
  }

  selectCard(idx: number): void {
    const scrollWidth = this.cardsContainer.nativeElement.parentElement.clientWidth;
    if (scrollWidth === 0) {
      setTimeout(() => {
        this.selectCard(idx);
      }, 500);
    } else {
      if (idx !== this.currentIdx) {
        this.scrollEl.scrollTo((idx - this.currentIdx) * scrollWidth, 0);
        this.currentIdx = idx;
      }
    }
  }

  swipe(e: TouchEvent | MouseEvent, when: string): void {
    const coord: [number, number] =
      e instanceof TouchEvent ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [e.clientX, e.clientY];

    if (when === "start") {
      this.swipeCoord = coord;
    } else if (when === "end") {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];

      if (
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        const swipe = direction[0] < 0 ? "next" : "previous";
        if (swipe === "next" && this.currentIdx + 1 < this.cards.length) {
          this.selectCard(this.currentIdx + 1);
        }
        if (swipe === "previous" && this.currentIdx > 0) {
          this.selectCard(this.currentIdx - 1);
        }
      }
    }
  }

  @HostListener("window:resize")
  onResize(): void {
    const toDisable = this.enableSwiperAtSize > 0 && window.innerWidth > this.enableSwiperAtSize;
    if (toDisable != this.swiperDisabled) {
      this.cardsContainer.nativeElement.style.width = !toDisable ? `${this.cards.length * 100}%` : "auto";
      this.swiperDisabled = toDisable;
      if (toDisable) {
        this.scrollEl.parentElement.classList.add("swipe-disabled");
      } else {
        this.scrollEl.parentElement.classList.remove("swipe-disabled");
      }
    }
    this.scrollEl.scrollLeft = 0;
    this.selectCard(0);
  }
}
