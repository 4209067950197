<div [attr.id]="id" class="grid-panel">
  <div
    *ngIf="!readOnly && !editOnly"
    [attr.id]="id + '-add'"
    class="grid-panel-row"
    [ngClass]="isAddMode ? 'selected' : selectedRow !== null ? 'hidden' : ''"
    (click)="select(null)"
  >
    <div class="toggle-col">
      <i *ngIf="!isAddMode" class="fal fa-plus-circle"></i>
      <i
        *ngIf="isAddMode"
        class="fal fa-times-circle"
        (click)="cancel($event)"
        matTooltip="Close"
        matTooltipClass="ignite-tool-tip"
        matTooltipPosition="above"
        matTooltipShowDelay="500"
      ></i>
    </div>
    <div class="row-content">
      <div *ngIf="!isAddMode" id="ignite-grid-add-row" class="add-field-col">Add {{ name }}</div>
      <div
        *ngFor="let col of columnsDef"
        class="header-col"
        [style.visibility]="
          isAddMode && !col.customComponent && (col.shownWhenSelected === undefined || col.shownWhenSelected) ? 'visible' : 'hidden'
        "
        [style.flex]="col.widthPercent === undefined ? '1 1 auto' : '0 0 ' + col.widthPercent + '%'"
        [style.maxWidth]="col.widthPercent === undefined ? 'auto' : col.widthPercent + '%'"
        [style.textAlign]="col.alignment === undefined ? 'auto' : col.alignment"
      >
        {{ getFieldValue(selectedRow, col.field, true) }}
      </div>
    </div>
  </div>

  <div [attr.id]="id + '-header'" class="grid-panel-header" *ngIf="!isGridEmpty() && (showHeaderWhenItemSelected || selectedRow === null)">
    <div *ngIf="expandable" class="spacer-col">&nbsp;</div>
    <div class="row-content">
      <div
        [id]="col.field + '-col-header' | replace : '[,]' : '-'"
        class="header-col-title"
        [class.hidden-in-mobile]="col.hiddenInMobile"
        *ngFor="let col of columnsDef; let ci = index"
        [style.flex]="col.widthPercent === undefined ? (ci > 0 ? '0 0 auto' : '1 1 auto') : '0 0 ' + col.widthPercent + '%'"
        [style.maxWidth]="col.widthPercent === undefined ? 'auto' : col.widthPercent + '%'"
        [style.justify-content]="col.alignment === undefined ? 'normal' : col.alignment"
      >
        <i
          *ngIf="col.sortable && selectedRow === null"
          class="fas"
          [class.fa-sort-up]="(col.isPrimarySort || multiColumnsSort) && col.sortDirection === 'asc'"
          [class.fa-sort-down]="(col.isPrimarySort || multiColumnsSort) && col.sortDirection === 'desc'"
          [class.fa-sort]="!col.isPrimarySort && !multiColumnsSort"
          [class.secondary]="!col.isPrimarySort"
          (click)="onSort(col)"
        ></i>
        <span (click)="onSort(col)">
          {{ col.header }}
        </span>
      </div>
    </div>
  </div>
  <div
    [attr.id]="id + '-row-' + i"
    class="grid-panel-row"
    *ngFor="let row of gridRows; index as i"
    [ngClass]="row.selected ? 'selected' : !accordion && selectedRow !== null ? 'hidden' : ''"
    (click)="select(row, $event)"
  >
    <div *ngIf="expandable" class="toggle-col">
      <i *ngIf="!row.selected" class="fal fa-arrow-circle-right"></i>
      <i *ngIf="row.selected" class="fal fa-times-circle" (click)="cancel($event)"></i>
    </div>
    <div class="row-content">
      <div
        [ngClass]="col.field + '-col' | replace : '[,]' : '-'"
        class="header-col"
        [class.hidden-in-mobile]="col.hiddenInMobile"
        [class.secondary-column]="ci > 0"
        *ngFor="let col of columnsDef; let ci = index"
        [style.flex]="col.widthPercent === undefined ? (ci > 0 ? '0 0 auto' : '1 1 auto') : '0 0 ' + col.widthPercent + '%'"
        [style.maxWidth]="col.widthPercent === undefined ? 'auto' : col.widthPercent + '%'"
        [style.textAlign]="col.alignment === undefined ? 'auto' : col.alignment"
      >
        <ignite-grid-custom
          *ngIf="col.customComponent && (col.shownWhenSelected || !row.selected)"
          [component]="col.customComponent"
          [value]="row"
          [rowIndex]="i"
        ></ignite-grid-custom>
        <span *ngIf="!col.customComponent && (col.shownWhenSelected === undefined || col.shownWhenSelected || !row.selected)">{{
          getFieldValue(row, col.field, true, col.mapValueFunction)
        }}</span>
      </div>
    </div>
  </div>

  <div #panelContent *ngIf="!useCustomForm" class="grid-row-details" [class.accordion]="accordion">
    <ignite-form
      *ngIf="selectedRow !== null"
      id="ignite-form"
      [name]="name"
      [fieldsDef]="fieldsDef"
      [readOnly]="readOnly || (addOnly && !isAddMode)"
      [data]="selectedRow"
      [isAddMode]="isAddMode"
      (addEvent)="addRow($event)"
      (editEvent)="editRow($event)"
      (cancelEvent)="cleanUp()"
    ></ignite-form>
  </div>
</div>
