<ignite-card id="team-dashboard-header">
  <div card-title i18n>You're all set. Control your teams from here.</div>
  <div card-content i18n>Add team members, create locations and find helpful hints.</div>
</ignite-card>
<div>
  <mat-label class="ignite-label">Tabs style</mat-label>
  <mat-radio-group class="horizontal-layout" [(ngModel)]="tabStyleSelected">
    <mat-radio-button *ngFor="let s of tabStyles" [value]="s.value">
      {{ s.viewValue }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Form</span>
    </ng-template>
    <br />
    <ignite-panel [panelTitle]="['Australian Agency', 'A12']" [panelMenuOptions]="panelMenuOptions">
      <form [formGroup]="form">
        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label floatLabel>Outline form field with error and icon</mat-label>
          <input matInput placeholder="pat@example.com" [formControl]="email" class="has-tooltip" required />
          <i class="fal fa-question-circle ignite-field-tooltip" ignitetooltip="abc"></i>
          <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>

        <ignite-surname id="surname" label="Surname" [frmCtrl]="form.get('surname')" placeholder="Enter a surname"></ignite-surname>

        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label floatLabel>Notes (text-area)</mat-label>
          <textarea
            matInput
            placeholder="Enter your notes here..."
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            formControlName="testInput"
            required
          ></textarea>
          <mat-error *ngIf="form.get('testInput').hasError('required')">Notes are required</mat-error>
        </mat-form-field>

        <div>
          <ignite-date label="Date of birth" formControlName="dob"></ignite-date>
        </div>

        <div>
          <ignite-duration label="Expiry time" timeUnit="milliseconds" formControlName="expiry"></ignite-duration>
        </div>

        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label floatLabel>Regular date</mat-label>
          <input matInput class="ignite-date-input" formControlName="regularDate" [matDatepicker]="picker" placeholder="Enter a date" />
          <mat-datepicker ignite-date-picker #picker color="primary"></mat-datepicker>
          <mat-error *ngIf="form.get('regularDate').hasError('required') && !form.get('regularDate').hasError('matDatepickerParse')"
            >Date is required</mat-error
          >
          <mat-error *ngIf="form.get('regularDate').hasError('matDatepickerParse')" i18n>Invalid date</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Basic Select</mat-label>
          <mat-select ignite-select placeholder="pick one">
            <mat-option *ngFor="let food of foods" [value]="food.value">
              {{ food.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Basic Select with just one option</mat-label>
          <mat-select ignite-select placeholder="pick one">
            <mat-option *ngFor="let food of buffet" [value]="food.value">
              {{ food.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Multi Select</mat-label>
          <mat-select ignite-select multiple placeholder="pick one or more">
            <mat-option *ngFor="let food of foods" [value]="food.value">
              {{ food.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <ignite-auto-complete
            id="id - autocomplete"
            label="Autocomplete for form"
            [selectOptions]="options"
            [frmCtrl]="myControl"
            placeholder="Pick one"
          ></ignite-auto-complete>
        </div>
        <div>
          <ignite-telephone-input
            label="Telephone number"
            placeholder="Telephone input for test takers"
            required="true"
            [countryCallingCodes]="countryCallingCodes"
            formControlName="phone"
          ></ignite-telephone-input>
        </div>

        <ignite-alert>
          <div alert-title>Match found</div>
          <div alert-content>
            This email address is associated with an existing Test Taker account but the chosen date of birth does not match. Please choose
            a different email address or enter in a different date of birth.
          </div>
        </ignite-alert>

        <mat-checkbox formControlName="isActive">Is account active </mat-checkbox>
        <br />

        <mat-label class="ignite-label">Radio buttons - horizontal layout on desktop</mat-label>
        <mat-radio-group class="horizontal-layout" formControlName="radiob">
          <mat-radio-button *ngFor="let food of foods" [value]="food.value">
            {{ food.viewValue }}
          </mat-radio-button>
          <mat-error *ngIf="form.get('radiob').hasError('notSelected')" i18n> Required</mat-error>
        </mat-radio-group>

        <mat-label class="ignite-label">Radio buttons - vertical layout</mat-label>
        <mat-radio-group>
          <mat-radio-button *ngFor="let food of foods" [value]="food.value">
            {{ food.viewValue }}
          </mat-radio-button>
        </mat-radio-group>
        <br />
        <br />

        <div class="ignite-buttons-container">
          <div class="ignite-buttons">
            <div class="ignite-button-wrapper">
              <button mat-button class="ignite-button" color="primary" (click)="onAdd()">Add New Record</button>
            </div>
            <div class="ignite-button-wrapper">
              <button class="ignite-button" mat-button>Cancel</button>
            </div>
          </div>
          <hr />
        </div>
      </form>
    </ignite-panel>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Controls</span>
    </ng-template>
    <span ignitetooltip="Resend welcome email">Tooltip</span>
    <br />
    <br />
    <form [formGroup]="form">
      <ignite-password
        id="password"
        label="Password"
        [frmCtrl]="form.get('password')"
        placeholder="Enter a password"
        maxlength="40"
        [errorMessages]="[
          { key: 'required', message: 'Password is required' },
          { key: 'invalidPassword', message: 'Invalid password' }
        ]"
      ></ignite-password>
    </form>
    <mat-slide-toggle ignite-toggle>Toggle</mat-slide-toggle>
    <br />
    <br />
    <div>
      <a (click)="openTemplateSheetMenu()">Bottom sheet 1</a>
    </div>
    <div>
      <a (click)="openTemplateSheetMenu(2)">Bottom sheet 2</a>
    </div>
    <br />
    <div>Progress bar</div>
    <mat-progress-bar ignite-progress-bar mode="determinate" value="40"></mat-progress-bar>
    <br />
    <div>Progress spinner</div>
    <mat-progress-spinner ignite-progress-spinner class="example-margin" color="primary" mode="determinate" [value]="progressValue">
    </mat-progress-spinner>
    <br />
    <div class="ignite-buttons-container" style="width: 300px">
      <button class="ignite-button" (click)="showSnackbar('success')" mat-button>Show success snackbar</button>
      <button class="ignite-button" (click)="showSnackbar('alert')" mat-button>Show alert snackbar</button>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Stepper</span>
    </ng-template>
    <br />
    <app-stepper></app-stepper>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Side Menu</span>
    </ng-template>
    <br />
    <app-menu></app-menu>
  </mat-tab>
</mat-tab-group>

<ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>
  <div class="bottom-sheet">
    <img src="/assets/images/Goal.png" />
    <div class="title">Why do we need this information?</div>
    <ul>
      <li>To make sure we understand your study goals</li>
      <li>To calculate where you at now and the score goals you want to reach</li>
    </ul>
  </div>
</ng-template>

<ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet2>
  <div class="bottom-sheet">
    <img src="/assets/images/Focus.png" />
    <div>Do you feel you need to improve most in speaking, reading, writing or listening?</div>
  </div>
</ng-template>
<br /><br />
