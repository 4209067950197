/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- ControlValueAccessor interface*/
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
import { Subject } from "rxjs";
import { IgniteSelectOption } from "../../models/ignite-select-option";
import { CustomErrorMessage } from "../../models/ignite-custom-validator";

@Component({
  selector: "ignite-password",
  templateUrl: "./ignite-password.component.html",
  styleUrls: ["./ignite-password.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IgnitePasswordComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IgnitePasswordComponent),
      multi: true,
    },
  ],
})
export class IgnitePasswordComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges, Validator {
  static nextId = 0;
  unsubscribe$: Subject<void> = new Subject<void>();
  inputType = "password";
  specCharsTip = "A special character is a symbol that is not a letter or number.\nFor example:  ! @ # $ % ^ & * ( ) _ - + =";
  private timerId: any;
  private hasError: boolean;

  @Input() id: string;
  @Input() label = "";
  @Input() required = false;
  @Input() readonly = false;
  @Input() placeholder: string;
  @Input() frmCtrl: UntypedFormControl;
  @Input() maxlength: number;
  @Input() noStrengthCheck: boolean;
  @Input() errorMessages: CustomErrorMessage[] = [];

  @ViewChild("eyeCtrl") eyeCtrl: ElementRef<HTMLElement>;
  @ViewChild("strengths") strengths: ElementRef<HTMLElement>;

  public onTouched: () => void = () => {
    // do nothing
  };

  ngOnInit(): void {
    if (!Utility.IsNull(this.frmCtrl)) {
      this.frmCtrl.valueChanges.subscribe({
        next: () => {
          this.validateStrengths();
          this.setError();
        },
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.noStrengthCheck || Utility.IsNull(this.eyeCtrl) || Utility.IsNull(this.strengths)) {
      return;
    }
    let wrapper: any = this.eyeCtrl.nativeElement.closest(".mat-mdc-form-field");
    if (!Utility.IsNull(wrapper)) {
      wrapper = wrapper.getElementsByClassName("mat-mdc-form-field-subscript-wrapper");
      if (wrapper.length > 0) {
        wrapper[0].appendChild(this.strengths.nativeElement);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes): void {
    this.frmCtrl.updateValueAndValidity({ emitEvent: true });
  }

  isEmpty(): boolean {
    return Utility.IsEmptyOrNull(this.frmCtrl.value);
  }

  writeValue(val: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    val && this.frmCtrl.setValue(val, { emitEvent: true });
  }

  showValue(item: IgniteSelectOption): string {
    return item && item.text ? item.text : "";
  }

  registerOnChange(fn: any): void {
    this.frmCtrl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.frmCtrl.disable() : this.frmCtrl.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.frmCtrl.valid ? null : this.frmCtrl.errors;
  }

  onFocusout(): void {
    this.toggleShow(null, false);
  }

  toggleShow($event: Event, show: boolean) {
    if (!Utility.IsNull($event)) {
      $event.stopPropagation();
    }

    this.resetTimer();
    if (show) {
      if (this.inputType === "password") {
        this.eyeCtrl.nativeElement.classList.remove("hide");
        this.eyeCtrl.nativeElement.classList.add("show");
        this.inputType = "text";
      }
    } else {
      if (this.inputType === "text") {
        this.eyeCtrl.nativeElement.classList.remove("show");
        this.eyeCtrl.nativeElement.classList.add("hide");
        this.inputType = "password";
      }
    }
  }

  private resetTimer() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => this.toggleShow(null, false), 30000);
  }

  private validateStrengths(): void {
    this.hasError = false;
    if (this.noStrengthCheck) {
      return;
    }

    const value = this.frmCtrl.value;
    if (Utility.IsEmptyOrNull(value)) {
      this.setState("size-strength", "unchecked");
      this.setState("number-strength", "unchecked");
      this.setState("cap-strength", "unchecked");
      this.setState("lower-strength", "unchecked");
      this.setState("schar-strength", "unchecked");
      return;
    }

    if (value.length < 8 || value.length > 40) {
      this.setState("size-strength", "invalid");
    } else {
      this.setState("size-strength", "valid");
    }

    if (value.length > 0) {
      this.setState("number-strength", new RegExp(/[0-9]/).test(value) ? "valid" : "invalid");
      this.setState("cap-strength", new RegExp(/[A-Z]/).test(value) ? "valid" : "invalid");
      this.setState("lower-strength", new RegExp(/[a-z]/).test(value) ? "valid" : "invalid");
      this.setState("schar-strength", new RegExp(/[~`! @#$%^&*()_\-+={[\]}|\\:;"'<,>.?/]/).test(value) ? "valid" : "invalid");
    }
  }

  private setState(strengthName: string, state: string): void {
    const e = this.strengths.nativeElement.getElementsByClassName(strengthName)[0];
    e.classList.remove("unchecked");
    e.classList.remove("valid");
    e.classList.remove("invalid");
    e.classList.add(state);

    if (!this.hasError && state === "invalid") {
      this.hasError = true;
    }
  }

  private setError(): void {
    if (this.hasError) {
      if (!this.frmCtrl.hasError("invalidPassword")) {
        this.frmCtrl.setErrors({ invalidPassword: true });
      }
    } else {
      if (this.frmCtrl.hasError("invalidPassword")) {
        this.frmCtrl.setErrors(null);
      }
    }
  }
}
