/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- Angular ControlValueAccessor defined interface */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, forwardRef } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
import dayjs from "dayjs";
import duration, { DurationUnitType } from "dayjs/plugin/duration";

@Component({
  selector: "ignite-duration",
  templateUrl: "ignite-duration.component.html",
  styleUrls: ["ignite-duration.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IgniteDurationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IgniteDurationComponent),
      multi: true,
    },
  ],
})
export class IgniteDurationComponent implements ControlValueAccessor {
  @Input() label = "";
  @Input() timeUnit: DurationUnitType = "milliseconds";
  duration: duration.Duration;
  control: AbstractControl;
  touched = false;
  disabled = false;
  private currentError: ValidationErrors;

  form: UntypedFormGroup = new UntypedFormGroup({
    day: new UntypedFormControl(""),
    month: new UntypedFormControl(""),
    year: new UntypedFormControl(""),
  });

  onTouched: () => void = () => {
    // do nothing
  };
  onChange = (_: any) => {
    // do nothing
  };

  writeValue(duration: number) {
    this.currentError = null;
    this.duration = dayjs.duration(duration, this.timeUnit);
    this.setValues();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.form.markAllAsTouched();
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  updateDuration() {
    const day = this.form.get("day").value as string;
    const month = this.form.get("month").value as string;
    const year = this.form.get("year").value as string;
    const formValues = {
      years: Utility.IsEmptyOrNull(year) ? 0 : Number(year),
      months: Utility.IsEmptyOrNull(month) ? 0 : Number(month),
      days: Utility.IsEmptyOrNull(day) ? 0 : Number(day),
    };
    this.duration = dayjs.duration(formValues);
    this.control.setValue(this.duration.asMilliseconds());
    this.control.markAsDirty();
    this.markAsTouched();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (Utility.IsNull(this.control)) {
      this.control = c;
    }

    return this.form.errors;
  }

  private setValues(): void {
    this.form.get("day").setValue(`${this.duration.days()}`);
    this.form.get("month").setValue(`${this.duration.months()}`);
    this.form.get("year").setValue(`${this.duration.years()}`);
  }
}
