/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import {
  AgeValidator,
  IgniteBaseComponent,
  IgnitePanelMenuOption,
  IgniteSnackbarComponent,
  IgniteSnackbarData,
  IgniteSnackbarType,
  MustSelectValidator,
  SaveDialogComponent,
} from "@ignite/ui-common";
import { DateUtility, PersonPlaceNameValidator } from "@ignite/ignite-common";
import { Observable, Subject, takeUntil, tap, timer } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import data from "../../../assets/data/countries.json";
import { MatDialog } from "@angular/material/dialog";

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-form-fields",
  templateUrl: "./form-fields.component.html",
  styleUrls: ["./form-fields.component.scss"],
})
export class FormFieldsComponent extends IgniteBaseComponent implements OnInit, AfterViewChecked {
  @ViewChild("templateBottomSheet") TemplateBottomSheet: TemplateRef<any>;
  @ViewChild("templateBottomSheet2") TemplateBottomSheet2: TemplateRef<any>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  progressValue = 0;
  tabStyleSelected = "0";
  form: UntypedFormGroup;
  countryCallingCodes = [];

  email = new UntypedFormControl("", [Validators.required, Validators.email]);
  foods: Food[] = [
    { value: "steak-0", viewValue: "Steak" },
    { value: "pizza-1", viewValue: "Pizza" },
    { value: "tacos-2", viewValue: "Tacos" },
  ];
  tabStyles: Food[] = [
    { value: "0", viewValue: "Button" },
    { value: "1", viewValue: "Menu" },
  ];
  myControl = new UntypedFormControl();
  options: any[] = [
    { value: "01", text: "One" },
    { value: "02", text: "Two" },
    { value: "03", text: "Three" },
  ];

  buffet: Food[] = [{ value: "buffet-0", viewValue: "Buffet" }];

  panelMenuOptions: IgnitePanelMenuOption[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog
  ) {
    super();
  }

  getErrorMessage(): string {
    if (this.email.hasError("required")) {
      return "You must enter a value";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  ngOnInit(): void {
    this.countryCallingCodes = data.countryCallingCodes;

    this.form = this.formBuilder.group({
      testInput: [""],
      phone: [""],
      radiob: ["", [MustSelectValidator()]],
      dob: ["", [Validators.required, AgeValidator()]],
      expiry: [65786400000],
      regularDate: ["", [Validators.required]],
      isActive: [true],
      password: ["", [Validators.required]],
      surname: ["", [Validators.required, new PersonPlaceNameValidator()]],
    });

    const panelOption = new IgnitePanelMenuOption("Follow", "Unfollow", "fa-user-plus", "fa-user-times", "fa-user-check");
    panelOption.selected = true;
    panelOption.toggleCallback = (selected: boolean) => {
      return new Observable<any>();
    };
    this.panelMenuOptions.push(panelOption);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
    this.form.get("radio")?.markAsDirty();
    this.form.get("radio")?.markAsTouched();
  }

  onAdd(): void {
    this.form.get("dob")?.updateValueAndValidity();
    this.form.get("radiob")?.markAsDirty();
    this.form.get("radiob")?.updateValueAndValidity();

    const stopPolling$ = new Subject<void>();
    const stopTime = DateUtility.now.add(20, "seconds");

    const archiveUser$ = timer(0, 3000).pipe(
      takeUntil(stopPolling$),
      tap(() => {
        if (DateUtility.now.isAfter(stopTime)) {
          stopPolling$.next();
        }
      })
    );
    this.dialog
      .open(SaveDialogComponent, {
        maxWidth: "290px",
        maxHeight: "310px",
        disableClose: true,
        data: {
          isSaved$: archiveUser$,
          savingMessage: "Adding account...",
          savedMessage: "Account has been added.",
        },
      })
      .afterClosed()
      .subscribe();
  }

  onTabChange($event: MatTabChangeEvent): void {
    if ($event.index === 1) {
      this.progressValue = 0;
      setInterval(() => {
        if (this.progressValue >= 100) {
          this.progressValue = 0;
        } else {
          this.progressValue += 2;
        }
      }, 200);
    }
  }

  openTemplateSheetMenu(sheet = 1) {
    if (sheet === 1) {
      this.bottomSheet.open(this.TemplateBottomSheet);
    } else {
      this.bottomSheet.open(this.TemplateBottomSheet2);
    }
  }

  closeTemplateSheetMenu() {
    this.bottomSheet.dismiss();
  }

  showSnackbar(type: string): void {
    if (type === "alert") {
      const ref = this.snackBar.openFromComponent(IgniteSnackbarComponent, {
        data: new IgniteSnackbarData("Something went wrong. Try again", IgniteSnackbarType.Alert, "RETRY"),
      });
      ref.onAction().subscribe(() => alert("do retry"));
    }
    if (type === "success") {
      this.snackBar.openFromComponent(IgniteSnackbarComponent, {
        data: new IgniteSnackbarData("Everything is good. Please proceed.", IgniteSnackbarType.Success),
      });
    }
  }
}
