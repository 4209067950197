import { Component, Input } from "@angular/core";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";
import { IgniteImageDef } from "../../models/ignite-image-def";
import { MatDialog } from "@angular/material/dialog";
import { IgniteImageDialogComponent } from "./ignite-image-dialog/ignite-image-dialog.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-image-carousel",
  templateUrl: "./ignite-image-carousel.component.html",
  styleUrls: ["./ignite-image-carousel.component.scss"],
})
export class IgniteImageCarouselComponent extends IgniteBaseComponent {
  @Input() imageDefs: IgniteImageDef[];

  constructor(private dialog: MatDialog) {
    super();
  }

  expandImage(imageDef: IgniteImageDef): void {
    const dialog = this.dialog.open(IgniteImageDialogComponent, {
      data: imageDef,
    });
    dialog.afterClosed().subscribe();
  }
}
