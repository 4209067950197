import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { SearchFilterGroup, Utility } from "@ignite/ignite-common";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-search-filter",
  templateUrl: "./ignite-search-filter.component.html",
  styleUrls: ["./ignite-search-filter.component.scss"],
})
export class IgniteSearchFilterComponent implements AfterViewInit {
  @ViewChild("toggle") button: HTMLButtonElement;
  @ViewChild("popup") popup: ElementRef<HTMLElement>;
  @Input() filterGroup: SearchFilterGroup;
  @Output() Open = new EventEmitter<SearchFilterGroup>();
  @Output() Close = new EventEmitter<SearchFilterGroup>();

  isOpened = false;
  private parentContainer: HTMLElement;

  constructor(private el: ElementRef<Element>) {}

  ngAfterViewInit(): void {
    this.parentContainer = this.el.nativeElement.closest(".search-panel-row");
  }

  getButtonName(): string {
    const selected = this.filterGroup.filters.filter((f) => f.selected);
    return selected.length === 1 ? selected[0].displayName : this.filterGroup.name;
  }

  hasSelected(): boolean {
    return this.filterGroup.filters.filter((f) => f.selected).length > 0;
  }

  togglePopup($event: Event = null, stayOpened = false): void {
    if (!Utility.IsNull($event)) {
      $event.stopPropagation();
    }

    if (this.filterGroup.filters.length === 1) {
      this.filterGroup.filters[0].selected = !this.filterGroup.filters[0].selected;
      this.Close.emit(this.filterGroup);
    } else {
      this.isOpened = stayOpened || !this.isOpened;
      if (this.isOpened) {
        setTimeout(() => {
          const parentPosition = this.parentContainer.getBoundingClientRect();
          const position = this.el.nativeElement.getBoundingClientRect();
          if (position.right > parentPosition.right) {
            this.parentContainer.scrollBy(position.right - parentPosition.right + 5, 0);
            setTimeout(() => this.togglePopup(null, true), 100);
          } else if (position.left + 25 < parentPosition.left) {
            this.parentContainer.scrollBy(-(parentPosition.left - position.left - 14), 0);
            setTimeout(() => this.togglePopup(null, true), 100);
          } else {
            const popupEl = this.popup.nativeElement;
            const popupWidth = position.width < 145 || window.innerWidth <= 640 ? 288 : 360;
            const left = position.right - popupWidth;
            popupEl.style.left = `${left < 0 ? position.left + 14 : left}px`;
            popupEl.style.top = `${position.top + position.height + 3}px`;

            this.Open.emit(this.filterGroup);
          }
        }, 0);
      } else {
        this.Close.emit(this.filterGroup);
      }
    }
  }

  clearFilter(): void {
    this.filterGroup.filters.map((f) => (f.selected = false));
  }
}
