/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- Angular ControlValueAccessor defined interface */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, OnInit, forwardRef } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";
import { DateUtility, Utility } from "@ignite/ignite-common";

@Component({
  selector: "ignite-date",
  templateUrl: "ignite-date.component.html",
  styleUrls: ["ignite-date.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IgniteDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IgniteDateComponent),
      multi: true,
    },
  ],
})
export class IgniteDateComponent implements OnInit, ControlValueAccessor {
  @Input() label = "";
  dateValue: Date;
  control: AbstractControl;
  disabled = false;
  private currentError: ValidationErrors;

  form: UntypedFormGroup = new UntypedFormGroup({
    day: new UntypedFormControl(""),
    month: new UntypedFormControl(""),
    year: new UntypedFormControl(""),
  });

  days: string[];
  years: string[];

  months = [
    { value: "1", text: "January" },
    { value: "2", text: "February" },
    { value: "3", text: "March" },
    { value: "4", text: "April" },
    { value: "5", text: "May" },
    { value: "6", text: "June" },
    { value: "7", text: "July" },
    { value: "8", text: "August" },
    { value: "9", text: "September" },
    { value: "10", text: "October" },
    { value: "11", text: "November" },
    { value: "12", text: "December" },
  ];

  ngOnInit(): void {
    this.days = [];
    for (let i = 0; i < 31; i++) {
      this.days.push(`${i + 1}`);
    }
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let i = 0; i < 120; i++) {
      this.years.push(`${currentYear - i}`);
    }
  }

  onTouched: () => void = () => {
    // do nothing
  };
  onChange = (_: any) => {
    // do nothing
  };

  writeValue(dateValue: string | Date) {
    this.currentError = null;

    if (Utility.IsEmptyOrNull(dateValue)) {
      this.dateValue = null;
    } else if (dateValue instanceof Date) {
      this.dateValue = dateValue;
    } else {
      const localDate = DateUtility.localDateOnlyToUtcDateOnly(dateValue);
      this.dateValue = this.parseDate(localDate.toISOString());
    }
    this.setValues();
  }

  private parseDate(d: string): Date {
    const date = new Date(Date.parse(d));
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  updateDate() {
    const day = this.form.get("day").value as string;
    const month = this.form.get("month").value as string;
    const year = this.form.get("year").value as string;
    if (Utility.IsEmptyOrNull(day) || Utility.IsEmptyOrNull(month) || Utility.IsEmptyOrNull(year)) {
      this.dateValue = null;
    } else {
      const ds = Number(day) < 10 ? "0" + day : day;
      const ms = Number(month) < 10 ? "0" + month : month;
      this.dateValue = this.parseDate(`${year}-${ms}-${ds}`);
    }
    this.onTouched();
    this.setError();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (Utility.IsNull(this.control)) {
      this.control = c;
      this.setError();
    }

    if (Utility.IsNull(this.form.errors) && !Utility.IsNull(this.currentError)) {
      this.setFormError(this.currentError);
    }

    return this.form.errors;
  }

  setFormError(error: ValidationErrors): void {
    this.currentError = error;
    this.form.markAllAsTouched();

    const setErr = () => {
      this.form.get("day").setErrors(error);
      this.form.get("month").setErrors(error);
      this.form.get("year").setErrors(error);
      this.form.setErrors(error);
    };
    if (this.control.touched) {
      setTimeout(() => {
        setErr();
      });
    } else {
      setErr();
    }
  }

  private setError(): void {
    this.setFormError(null);
    const date = this.form.value;
    let isInvalid = false;
    if (!Utility.IsEmptyOrNull(this.dateValue)) {
      if (isNaN(this.dateValue.valueOf())) {
        isInvalid = true;
      } else {
        const dateISO = DateUtility.format(this.dateValue, "YYYY-MM-DD");
        const parts = dateISO.split("-");
        if (Number(parts[0]) !== Number(date.year) || Number(parts[1]) !== Number(date.month) || Number(parts[2]) !== Number(date.day)) {
          isInvalid = true;
        }
      }
    }

    if (isInvalid) {
      this.setFormError({ invalid: true });
      this.control.setValue(null);
    } else {
      this.control.setValue(this.dateValue);
      if (!this.control.valid) {
        this.setFormError(this.control.errors);
      }
    }
  }

  private setValues(): void {
    if (!Utility.IsEmptyOrNull(this.dateValue) && !isNaN(this.dateValue.valueOf())) {
      this.form.get("day").setValue(`${this.dateValue.getDate()}`);
      this.form.get("month").setValue(`${this.dateValue.getMonth() + 1}`);
      this.form.get("year").setValue(`${this.dateValue.getFullYear()}`);
    }
  }
}
