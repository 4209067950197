/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CanComponentDeactivate, NavigationService, Utility } from "@ignite/ignite-common";
import { Observable, of } from "rxjs";
import { debounceTime, map, takeWhile, tap } from "rxjs/operators";
import { UIControlType } from "../../models/ignite-control-type";
import { CustomErrorMessage } from "../../models/ignite-custom-validator";
import { IgniteGridFieldDef } from "../../models/ignite-grid-field-def";
import { PasswordValidator } from "../../validators/password.validator";
import { ConfirmDialogComponent } from "../dialogs/confirm-dialog/confirm-dialog.component";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";

@Component({
  selector: "ignite-form",
  templateUrl: "./ignite-form.component.html",
  styleUrls: ["./ignite-form.component.scss"],
})
export class IgniteFormComponent extends IgniteBaseComponent implements OnInit, OnDestroy, OnChanges, CanComponentDeactivate {
  static nextId = 0;
  @Input() id: string;
  @Input() name: string;
  @Input() fieldsDef: IgniteGridFieldDef[];
  @Input() data: any;
  @Input() readOnly: boolean;
  @Input() isAddMode: boolean;
  @Input() saveButtonLabel: string;
  @Output() addEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<boolean>();
  displayFields: IgniteGridFieldDef[];
  uiControlType = UIControlType;
  form: UntypedFormGroup;
  isSaving = false;
  initialized = false;

  constructor(private navService: NavigationService, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-form-${IgniteFormComponent.nextId++}`;
    }
  }

  ngOnChanges(c: SimpleChanges): void {
    if (!Utility.IsEmptyOrNull(this.fieldsDef) && !this.initialized) {
      this.initialized = true;
      this.navService.canDeactivateComponent = this;
      this.setupForm();
    }
    if (!Utility.IsNull(c.data?.currentValue)) {
      this.resetFormControls(c.data.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.navService.canDeactivateComponent = null;
  }

  private setupForm(): void {
    const group: any = {};
    this.displayFields = this.fieldsDef.filter((f) => f.controlType !== UIControlType.Hidden);

    this.fieldsDef.forEach((field) => {
      field.flatName = Utility.IsEmptyOrNull(field.flatName) ? field.name.replace(".", "-") : field.flatName;
      if (this.readOnly || (!Utility.IsEmptyOrNull(field.disabled) && field.disabled)) {
        group[field.flatName] = new UntypedFormControl({ value: "", disabled: true });
      } else {
        const fieldValidators = this.setupValidators(field);
        if (!Utility.IsNull(field.customAsyncValidator)) {
          field.customMessages.push(field.customAsyncValidator.customMessage);
          group[field.flatName] = new UntypedFormControl("", {
            validators: fieldValidators,
            asyncValidators: [field.customAsyncValidator.validator.bind(this)],
            updateOn: "blur",
          });
        } else {
          group[field.flatName] = new UntypedFormControl("", {
            validators: fieldValidators,
          });
        }
      }
    });

    this.form = new UntypedFormGroup(group);
  }

  private resetFormControls(data): void {
    this.form.reset();

    this.fieldsDef.forEach((f) => {
      const control = this.form.get(f.flatName);
      const disabled = this.readOnly ? true : Utility.IsEmptyOrNull(f.disabled) ? false : f.disabled;
      if (!disabled) {
        const canAdd = Utility.IsEmptyOrNull(f.canAdd) ? true : f.canAdd;
        const canEdit = Utility.IsEmptyOrNull(f.canEdit) ? true : f.canEdit;
        const toDisable = (this.isAddMode && !canAdd) || (!this.isAddMode && !canEdit);
        if (toDisable && !control.disabled) {
          control.reset({ value: "", disabled: true });
        }
        if (!toDisable && control.disabled) {
          control.reset({ value: "", disabled: false });
        }
      }
      if (!Utility.IsNull(data)) {
        const v = Utility.IsEmptyOrNull(data[f.flatName]) ? "" : data[f.flatName];
        if (f.controlType === UIControlType.Autocomplete) {
          control.setValue(f.selectOptions.find((o) => o.value === v));
        } else {
          control.setValue(v);
        }
      }
    });
  }

  getFieldValue(fieldName: string): any {
    if (!Utility.IsEmptyOrNull(this.fieldsDef)) {
      return this.form.get(fieldName).value;
    }
    return null;
  }

  cancel($event): void {
    this.canDeactivate().subscribe({
      next: (ok: boolean) => {
        // do nothing
      },
    });

    $event?.stopPropagation();
  }

  canDeactivate(): Observable<boolean> {
    if (!this.form.pristine && this.form.touched) {
      const dialog = this.dialog.open(ConfirmDialogComponent);
      dialog.componentInstance.setup("Changes you made have not been saved.", "Discard changes?", "Discard changes");
      return dialog.afterClosed().pipe(
        map((confirmed) => {
          return confirmed === true;
        }),
        tap((discard) => {
          if (discard) {
            this.cancelEvent.emit(true);
          }
        })
      );
    } else {
      this.cancelEvent.emit(true);
      return of(true);
    }
  }

  save(): void {
    this.isSaving = true;
    if (this.form.pending) {
      this.form.statusChanges
        .pipe(
          takeWhile((status) => status === "PENDING", true),
          debounceTime(500)
        )
        .subscribe({
          next: () => this.doSave(),
        });
    } else {
      this.doSave();
    }
  }

  doSave(): void {
    if (!this.form.invalid) {
      this.fieldsDef.forEach((f) => {
        const control = this.form.get(f.flatName);
        if (!control.disabled) {
          this.data[f.flatName] =
            f.controlType === UIControlType.Autocomplete && !Utility.IsNull(control.value) ? control.value.value : control.value;
        }
      });

      if (!this.isAddMode) {
        if (this.form.touched || !this.form.pristine) {
          this.editEvent.emit(this.unflattenRow(this.data));
        } else {
          this.cancelEvent.emit(true);
        }
      } else {
        this.addEvent.emit(this.unflattenRow(this.data));
      }
    }

    this.isSaving = false;
  }

  filterOptions(field: IgniteGridFieldDef) {
    const ctrl = this.form.get(field.name);
    if (!Utility.IsNull(ctrl)) {
      if (!ctrl.disabled) {
        return field.selectOptions.filter((s) => s.value !== "*");
      }
    }
    return field.selectOptions;
  }

  private setupValidators(field: IgniteGridFieldDef): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    field.customMessages = [];
    if (!Utility.IsNull(field.customValidators)) {
      field.customValidators.forEach((v) => {
        validators.push(v.validator);
        if (!Utility.IsNull(v.customMessages)) {
          v.customMessages.forEach((m) => {
            if (!field.customMessages.find((cm) => cm.key === m.key)) {
              field.customMessages.push(m);
            }
          });
        }
      });
    }

    if (field.required) {
      validators.push(Validators.required);
    }

    switch (field.controlType) {
      case UIControlType.InputPassword:
        validators.push(PasswordValidator());
        break;
    }

    return validators;
  }

  private unflattenRow(row: any): any {
    const ur = Object.assign({}, row);
    delete ur.selected;
    this.fieldsDef.forEach((f) => {
      if (f.flatName !== f.name) {
        Utility.SetDeep(ur, f.name, row[f.flatName]);
        delete ur[f.flatName];
      }
    });
    return ur;
  }
}
