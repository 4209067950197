import { DragDropModule } from "@angular/cdk/drag-drop";
import { Platform } from "@angular/cdk/platform";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule, Routes } from "@angular/router";
import { ConfirmDialogComponent } from "./components/dialogs/confirm-dialog/confirm-dialog.component";
import { NotepadDialogComponent } from "./components/dialogs/notepad-dialog/notepad-dialog.component";
import { ProgressDialogComponent } from "./components/dialogs/progress-dialog/progress-dialog.component";
import { IgniteActionCardComponent } from "./components/ignite-action-card/ignite-action-card.component";
import { IgniteAlertComponent } from "./components/ignite-alert/ignite-alert.component";
import { IgniteAutoCompleteComponent } from "./components/ignite-auto-complete/ignite-auto-complete.component";
import { IgniteBaseComponent } from "./components/ignite-base/ignite-base.component";
import { IgniteCardComponent } from "./components/ignite-card/ignite-card.component";
import { IgniteFooterComponent } from "./components/ignite-footer/ignite-footer.component";
import { IgniteGridCustomComponent } from "./components/ignite-grid-custom/ignite-grid-custom.component";
import { IgniteHelpComponent } from "./components/ignite-help/ignite-help.component";
import { IgniteMenuButtonComponent } from "./components/ignite-menu-button/ignite-menu-button.component";
import { IgniteMenuComponent } from "./components/ignite-menu/ignite-menu.component";
import { IgniteSideMenuComponent } from "./components/ignite-side-menu/ignite-side-menu.component";
import { IgniteSideMenuItemComponent } from "./components/ignite-side-menu-item/ignite-side-menu-item.component";
import { IgnitePaginatorComponent } from "./components/ignite-paginator/ignite-paginator.component";
import { IgnitePanelGridComponent } from "./components/ignite-panel-grid/ignite-panel-grid.component";
import { IgnitePanelMenuComponent } from "./components/ignite-panel-menu/ignite-panel-menu.component";
import { IgnitePanelComponent } from "./components/ignite-panel/ignite-panel.component";
import { IgnitePanelStepperComponent } from "./components/ignite-panel-stepper/ignite-panel-stepper.component";
import { IgnitePanelStepperItemComponent } from "./components/ignite-panel-stepper-item/ignite-panel-stepper-item.component";
import { IgniteProfileMenuComponent } from "./components/ignite-profile-menu/ignite-profile-menu.component";
import { IgniteSearchFilterComponent } from "./components/ignite-search-filter/ignite-search-filter.component";
import { IgniteSearchComponent } from "./components/ignite-search/ignite-search.component";
import { IgniteSnackbarComponent } from "./components/ignite-snackbar/ignite-snackbar.component";
import { IgniteSoloCardComponent } from "./components/ignite-solo-card/ignite-solo-card.component";
import { IgniteTelephoneInputComponent } from "./components/ignite-telephone-input/ignite-telephone-input.component";
import { IgniteToolbarComponent } from "./components/ignite-toolbar/ignite-toolbar.component";
import { CustomHostDirective } from "./directives/custom-host.directive";
import { IframeClickerDirective } from "./directives/iframe-clicker.directive";
import { IgniteDashboardCardDirective } from "./directives/ignite-dashboard-card.directive";
import { IgniteDatePickerDirective } from "./directives/ignite-date-picker.directive";
import { IgnitePasswordDirective } from "./directives/ignite-password.directive";
import { IgniteSelectDirective } from "./directives/ignite-select.directive";
import { IgniteStepperDirective } from "./directives/ignite-stepper.directive";
import { IgniteToggleDirective } from "./directives/ignite-toggle.directive";
import { InputTrimDirective } from "./directives/input-trim.directive";
import { ReplacePipe } from "./pipes/replace.pipe";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { IgniteDateAdapter } from "./services/date.adapter";
import { IgniteProgressBarDirective } from "./directives/ignite-progress-bar.directive";
import { IgniteLabelDirective } from "./directives/ignite-label.directive";
import { IgniteFormComponent } from "./components/ignite-form/ignite-form.component";
import { IgniteProgressSpinnerDirective } from "./directives/ignite-progress-spinner.directive";
import { IgniteSidebarComponent } from "./components/ignite-sidebar/ignite-sidebar.component";
import { IgniteSidebarButtonComponent } from "./components/ignite-sidebar/ignite-sidebar-button/ignite-sidebar-button.component";
import { IgniteSidenavDirective } from "./directives/ignite-sidenav.directive";
import { NgxEditorModule } from "ngx-editor";
import { NotepadCustomMenuComponent } from "./components/dialogs/notepad-dialog/custom-menu/custom-menu.component";
import { IgniteSwiperComponent } from "./components/ignite-swiper/ignite-swiper.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SaveDialogComponent } from "./components/dialogs/save-dialog/save-dialog.component";
import { IgniteDateComponent } from "./components/ignite-date/ignite-date.component";
import { IgniteTooltipDirective } from "./directives/ignite-tool-tip";
import { IgnitePasswordComponent } from "./components/ignite-password/ignite-password.component";
import { IgniteSurnameComponent } from "./components/ignite-surname/ignite-surname.component";
import { IgniteDurationComponent } from "./components/ignite-duration/ignite-duration.component";
import { IgniteImageCarouselComponent } from "./components/ignite-image-carousel/ignite-image-carousel.component";
import { IgniteImageDialogComponent } from "./components/ignite-image-carousel/ignite-image-dialog/ignite-image-dialog.component";

const routes: Routes = [];

@NgModule({
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    {
      provide: DateAdapter,
      useClass: IgniteDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-GB",
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "dd-mm-yyyy",
        },
        display: {
          dateInput: "d mmmm yyyy",
          monthYearLabel: "mmm yyyy",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "mmm yyyy",
        },
      },
    },
  ],
  declarations: [
    IgniteBaseComponent,
    CustomHostDirective,
    IframeClickerDirective,
    IgniteSelectDirective,
    IgniteDatePickerDirective,
    IgnitePasswordDirective,
    IgniteProgressBarDirective,
    IgniteProgressSpinnerDirective,
    IgniteSidenavDirective,
    IgniteStepperDirective,
    IgniteToggleDirective,
    IgniteDashboardCardDirective,
    IgniteLabelDirective,
    IgniteTooltipDirective,
    IgniteBaseComponent,
    IgniteMenuComponent,
    IgniteMenuButtonComponent,
    IgniteSideMenuComponent,
    IgniteSideMenuItemComponent,
    IgnitePaginatorComponent,
    IgniteToolbarComponent,
    IgniteSidebarComponent,
    IgniteSidebarButtonComponent,
    IgniteFooterComponent,
    IgnitePanelGridComponent,
    IgniteFormComponent,
    IgniteCardComponent,
    IgnitePanelComponent,
    IgnitePanelStepperComponent,
    IgnitePanelStepperItemComponent,
    IgnitePanelMenuComponent,
    IgniteSearchComponent,
    IgniteSearchFilterComponent,
    ConfirmDialogComponent,
    SaveDialogComponent,
    NotepadDialogComponent,
    ProgressDialogComponent,
    IgniteAutoCompleteComponent,
    IgniteGridCustomComponent,
    IgniteProfileMenuComponent,
    IgnitePanelMenuComponent,
    IgniteSnackbarComponent,
    IgniteSwiperComponent,
    IgniteDateComponent,
    IgniteDurationComponent,
    NotepadCustomMenuComponent,
    ReplacePipe,
    IgniteTelephoneInputComponent,
    InputTrimDirective,
    IgniteAlertComponent,
    IgniteSoloCardComponent,
    IgniteActionCardComponent,
    IgnitePasswordComponent,
    IgniteSurnameComponent,
    IgniteImageCarouselComponent,
    IgniteImageDialogComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    IgniteHelpComponent,
  ],
  imports: [
    CommonModule,
    MatBadgeModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatMenuModule,
    NgxEditorModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    CustomHostDirective,
    IframeClickerDirective,
    IgniteSelectDirective,
    IgniteDatePickerDirective,
    IgnitePasswordDirective,
    IgniteDashboardCardDirective,
    IgniteBaseComponent,
    IgniteMenuComponent,
    IgniteMenuButtonComponent,
    IgniteSideMenuComponent,
    IgniteSideMenuItemComponent,
    IgnitePaginatorComponent,
    IgniteToolbarComponent,
    IgniteSidebarComponent,
    IgniteSidebarButtonComponent,
    IgniteFooterComponent,
    IgnitePanelGridComponent,
    IgniteFormComponent,
    IgniteProgressBarDirective,
    IgniteProgressSpinnerDirective,
    IgniteSidenavDirective,
    IgniteCardComponent,
    IgnitePanelComponent,
    IgnitePanelStepperComponent,
    IgnitePanelStepperItemComponent,
    IgnitePanelMenuComponent,
    IgniteSearchComponent,
    IgniteSearchFilterComponent,
    IgniteSwiperComponent,
    ConfirmDialogComponent,
    SaveDialogComponent,
    NotepadDialogComponent,
    ProgressDialogComponent,
    IgniteAutoCompleteComponent,
    IgniteSnackbarComponent,
    IgniteGridCustomComponent,
    IgniteProfileMenuComponent,
    IgnitePanelMenuComponent,
    IgniteTelephoneInputComponent,
    IgniteHelpComponent,
    IgniteDateComponent,
    IgniteDurationComponent,
    ReplacePipe,
    InputTrimDirective,
    IgniteAlertComponent,
    IgniteSoloCardComponent,
    IgniteActionCardComponent,
    IgnitePasswordComponent,
    IgniteSurnameComponent,
    IgniteImageCarouselComponent,
    IgniteImageDialogComponent,
    IgniteStepperDirective,
    IgniteTooltipDirective,
    SafeUrlPipe,
    SafeHtmlPipe,
    IgniteLabelDirective,
    IgniteToggleDirective,
  ],
})
export class UiCommonModule {}
