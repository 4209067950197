/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { throwError } from "rxjs";
import { IgnitePanelMenuOption } from "../../models/ignite-panel-menu-option";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "ignite-panel-menu",
  templateUrl: "./ignite-panel-menu.component.html",
  styleUrls: ["./ignite-panel-menu.component.scss"],
})
export class IgnitePanelMenuComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  static nextId = 0;
  @Input() menuOptions: IgnitePanelMenuOption[];
  @ViewChild("panelMenu") panelMenu: ElementRef<HTMLElement>;
  @ViewChild("popup") popup: ElementRef<HTMLElement>;
  isOpened = false;
  isPositioning = false;
  id = "";

  ngOnInit(): void {
    this.id = `ignite-panel-menu-${IgnitePanelMenuComponent.nextId++}`;
    if (Utility.IsEmptyOrNull(this.menuOptions)) {
      this.menuOptions = [];
    }
  }

  private scroll = () => {
    if (window.innerWidth <= 640 && !this.isPositioning) {
      this.positionPopup();
    }
  };

  ngAfterViewInit(): void {
    if (!Utility.IsNull(this.panelMenu)) {
      const mainContainer = this.panelMenu.nativeElement.closest(".ignite-main-content-container");
      if (!Utility.IsNull(mainContainer)) {
        mainContainer.addEventListener("scroll", this.scroll, true);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.menuOptions = changes["menuOptions"].currentValue as IgnitePanelMenuOption[];
  }

  ngOnDestroy(): void {
    if (!Utility.IsNull(this.panelMenu)) {
      const mainContainer = this.panelMenu.nativeElement.closest(".ignite-main-content-container");
      if (!Utility.IsNull(mainContainer)) {
        mainContainer.removeEventListener("scroll", this.scroll, true);
      }
    }
  }

  toggleMenu($event: Event): void {
    const target = $event.target as HTMLElement;
    this.panelMenu.nativeElement.classList.add("clicked");
    setTimeout(() => this.panelMenu.nativeElement.classList.remove("clicked"), 100);

    if (!this.isOpened) {
      const mainContainer = this.panelMenu.nativeElement.closest(".ignite-main-content-container");
      (mainContainer as HTMLElement).click();
      setTimeout(() => {
        this.isOpened = true;
        this.positionPopup();
      }, 100);
    } else {
      this.isOpened = false;
    }
    $event.stopPropagation();
  }

  private positionPopup(): void {
    if (!Utility.IsNull(this.panelMenu) && !this.isPositioning) {
      this.isPositioning = true;
      if (window.innerWidth <= 640) {
        const pos = this.panelMenu.nativeElement.getBoundingClientRect();
        this.popup.nativeElement.style.top = `${pos.top + pos.height + 10}px`;
        this.scrollPopup();
      } else {
        this.popup.nativeElement.style.top = "";
        this.scrollPopup();
      }
    }
  }

  private scrollPopup(): void {
    setTimeout(() => {
      this.popup.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      this.isPositioning = false;
    }, 0);
  }

  onOptionClick($event: Event, option: IgnitePanelMenuOption): void {
    if (!Utility.IsEmptyOrNull(option.toggleCallback)) {
      option.toggleCallback(!option.selected).subscribe({
        next: (result: unknown) => {
          if (typeof result === "boolean") {
            option.selected = result;
          } else {
            option.selected = !option.selected;
          }
        },
        error: (error) => {
          option.selected = !option.selected;
          throwError(() => error);
        },
      });
    } else {
      option.selected = !option.selected;
    }
    this.isOpened = false;
    $event.stopPropagation();
  }

  @HostListener("window:click")
  onWindowClick(): void {
    this.isOpened = false;
  }

  @HostListener("window:resize")
  onResize(): void {
    this.isOpened = false;
  }
}
