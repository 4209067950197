<mat-form-field
  class="ignite-auto-complete-field"
  [attr.id]="id"
  [hideRequiredMarker]="true"
  appearance="outline"
  [class.is-empty]="isEmpty()"
>
  <mat-label *ngIf="label !== ''" floatLabel>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="frmCtrl"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    [required]="required"
    [readonly]="readonly"
    [class.has-tooltip]="tooltip !== ''"
  />
  <i *ngIf="frmCtrl?.enabled && tooltip !== ''" class="fal fa-question-circle ignite-field-tooltip" ignitetooltip="{{ tooltip }}"></i>

  <mat-error *ngIf="frmCtrl?.hasError('required')" i18n>{{ label }} is required</mat-error>
  <mat-error *ngIf="frmCtrl?.hasError('unmatchedItem')" i18n>{{ label }} is invalid</mat-error>
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="showValue">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{ option.text }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
