import { Component, OnInit, ViewChild } from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { NotepadDialogComponent } from "@ignite/ui-common";
import { IgniteImageDef } from "libs/ui-common/src/lib/models/ignite-image-def";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild(NotepadDialogComponent) notepadDialog: NotepadDialogComponent;

  notifications = "";
  sideMenuOpened = true;
  notes = "";
  imageDefs: IgniteImageDef[];

  constructor() {}

  ngOnInit(): void {
    let notiCount = 1;
    setInterval(() => {
      if (notiCount >= 10) {
        this.notifications = "";
        notiCount = 1;
      } else {
        this.notifications = `${notiCount++}`;
      }
    }, 2000);

    this.imageDefs = [];
    this.imageDefs.push({
      imageUrl: "/assets/images/learn/guided_practice_all_skills.png",
      title: "Skills",
      description: "Guided practice all skills",
      expandable: true,
    });
    this.imageDefs.push({
      imageUrl: "/assets/images/learn/guided_practice_listening.png",
      title: "Listening",
      description: "Guided practice listening",
      expandable: true,
    });
  }

  sideBarClick(msg: string): void {
    if (msg === "menu") {
      this.sideMenuOpened = !this.sideMenuOpened;
    }
    if (msg === "notePad") {
      if (!Utility.IsNull(this.notepadDialog)) {
        this.notepadDialog.toggle();
      }
    }
  }

  onNotesChanged(html: object) {
    console.log(html);
  }
}
