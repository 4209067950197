import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IgniteImageDef } from "../../../models/ignite-image-def";
import { IgniteBaseComponent } from "../../ignite-base/ignite-base.component";

@Component({
  selector: "ignite-image-dialog",
  templateUrl: "./ignite-image-dialog.component.html",
  styleUrls: ["./ignite-image-dialog.component.scss"],
})
export class IgniteImageDialogComponent extends IgniteBaseComponent {
  constructor(public dialogRef: MatDialogRef<IgniteImageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IgniteImageDef) {
    super();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
