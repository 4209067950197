<div>
  <table class="customTable">
    <tr *ngFor="let item of data.details">
      <td>{{ item.description }}</td>
      <td style="text-align: right">
        <span *ngIf="item.kind == 'subtotal' || item.kind == 'total'">Total: </span>{{ getPrice(item.price) }}
        <div *ngIf="item.kind == 'subtotal'">&nbsp;</div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td style="text-align: right"><a target="_blank" href="https://apple.com">View order summary</a></td>
    </tr>
  </table>
</div>
