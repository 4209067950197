<ignite-card id="team-dashboard-header">
  <div card-title i18n>Different styles of grids</div>
  <div card-content i18n>Data controls that allow for data querying, addition, editing, deletion, and reading.</div>
</ignite-card>
<mat-tab-group animationDuration="0ms">
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="true">Grid</span>
    </ng-template>
    <ignite-search placeholder="Search by name or PTE ID" (search)="onSearch($event)" [searchCriteria]="searchCriteria"></ignite-search>
    <div class="grid-container">
      <ignite-panel-grid
        id="ignite-locations-grid"
        name="location"
        [dataSource]="locationDS"
        [columnsDef]="gridColumns"
        [fieldsDef]="gridFields"
        (selectEvent)="paginator.onGridSelect($event)"
        (addEvent)="onAddLocation($event)"
        (pageEvent)="onPaging($event)"
        (sortEvent)="onSort($event)"
      ></ignite-panel-grid>
      <br />
      <ignite-paginator #paginator (page)="onPaging($event)" [dataSource]="locationDS"></ignite-paginator>
    </div>
  </mat-tab>
  <mat-tab label="Accordion">
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="true">Accordion</span>
    </ng-template>
    <div class="grid-container flex-height-row">
      <ignite-panel-grid
        id="ignite-accordion-grid"
        name="accordion"
        [dataSource]="locationDS"
        [columnsDef]="gridColumns"
        [fieldsDef]="gridFields"
        [accordion]="true"
        [readOnly]="true"
      ></ignite-panel-grid>
    </div>
  </mat-tab>
  <mat-tab label="Custom Accordion">
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="true"> Custom Accordion</span>
    </ng-template>
    <div class="grid-container flex-height-row" style="max-width: 900px; margin-left: auto; margin-right: auto">
      <ignite-panel-grid
        #igniteCustomAccordionGrid
        name="customAccordion"
        [dataSource]="customDS"
        [columnsDef]="customColumns"
        [fieldsDef]="customFields"
        [accordion]="true"
        [readOnly]="true"
        [showHeaderWhenItemSelected]="true"
      >
      </ignite-panel-grid>
    </div>
  </mat-tab>
</mat-tab-group>
<br /><br />
