/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable  @typescript-eslint/no-unsafe-call */
import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";

@Directive({
  selector: "[ignitetooltip]",
  providers: [MatTooltip],
})
export class IgniteTooltipDirective {
  @Input() ignitetooltip: string;

  constructor(private elementRef: ElementRef, private tooltip: MatTooltip) {}

  @HostListener("mouseover") mouseover() {
    this.tooltip.message = this.ignitetooltip;
    this.tooltip.show();
  }
  @HostListener("mouseleave") mouseleave() {
    this.tooltip.hide();
  }

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    event.stopPropagation();
  }
}
