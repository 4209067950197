<mat-form-field class="ignite-password-field" [attr.id]="id" [hideRequiredMarker]="true" appearance="outline" [class.is-empty]="isEmpty()">
  <mat-label *ngIf="label !== ''" floatLabel>{{ label }}</mat-label>
  <input
    matInput
    [type]="inputType"
    [formControl]="frmCtrl"
    [placeholder]="placeholder"
    [required]="required"
    [readonly]="readonly"
    [maxlength]="maxlength"
  />

  <mat-error class="ignite-custom-error" *ngFor="let cm of errorMessages">
    <span *ngIf="frmCtrl.hasError(cm.key)">{{ cm.message }}</span>
  </mat-error>

  <div
    #eyeCtrl
    class="show-hide-password hide"
    tabindex="-1"
    (click)="toggleShow($event, inputType === 'password')"
    (focusout)="onFocusout()"
  >
    <i class="fal fa-eye"></i><i class="fal fa-eye-slash"></i>
  </div>
</mat-form-field>

<div #strengths *ngIf="!noStrengthCheck" class="password-strength-wrapper">
  <div class="strengths">
    <div class="password-strength">
      <span class="size-strength unchecked"
        ><span><i class="fas fa-check-circle"></i><i class="far fa-times-circle"></i></span> </span
      ><span>8-40 characters</span>
    </div>
    <div class="password-strength">
      <span class="cap-strength unchecked"
        ><span><i class="fas fa-check-circle"></i><i class="far fa-times-circle"></i></span> </span
      ><span>One uppercase letter</span>
    </div>
    <div class="password-strength">
      <span class="lower-strength unchecked"
        ><span><i class="fas fa-check-circle"></i><i class="far fa-times-circle"></i></span> </span
      ><span>One lowercase letter</span>
    </div>
    <div class="password-strength">
      <span class="number-strength unchecked"
        ><span><i class="fas fa-check-circle"></i><i class="far fa-times-circle"></i></span> </span
      ><span>One number</span>
    </div>
    <div class="password-strength">
      <span class="schar-strength unchecked"
        ><span><i class="fas fa-check-circle"></i><i class="far fa-times-circle"></i></span> </span
      ><span
        >One special character
        <i
          class="far fa-info-circle spec-char-info"
          ignitetooltip="{{ specCharsTip }}"
        ></i
      ></span>
    </div>
  </div>
</div>
