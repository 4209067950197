/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BehaviorSubject, Observable, throwError, of } from "rxjs";
import { CollectionViewer } from "@angular/cdk/collections";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { QueryParams, PageResponse, IgniteDataSource } from "@ignite/ignite-common";
import { PaymentService } from "../services/payment-service";
import { Payment } from "../models/payment";
import { IgniteGridUpdateResult } from "../../../libs/ui-common/src";

export class PaymentDataSource implements IgniteDataSource<Payment> {
  private paymentSubject = new BehaviorSubject<Payment[]>([]);
  private paymentUpdateSubject = new BehaviorSubject<IgniteGridUpdateResult>(null);

  pageResponse$ = new BehaviorSubject<PageResponse<any>>(new PageResponse<any>());

  constructor(private paymentService: PaymentService) {}

  connect(collectionViewer: CollectionViewer): Observable<Payment[]> {
    return this.paymentSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(collectionViewer: CollectionViewer): void {}

  connectUpdates(): Observable<any> {
    return this.paymentUpdateSubject.asObservable();
  }

  loadPayments(query: QueryParams) {
    this.paymentService
      .getPayments(query)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 404) {
            return throwError(error);
          }
          return of(new PageResponse<any>());
        })
      )
      .subscribe((pr) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.paymentSubject.next(pr.items);
        this.pageResponse$.next(pr);
      });
  }

  clearResults() {
    this.paymentSubject.next([]);
  }
}
